import React from 'react';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import {hydrate, render} from "react-dom";

window.url_arrival = 'https://eurotrans.by/local/components/travelsoft/eurotrans.search_form/ajax/arrivalpoints.php';
window.url_dates = 'https://eurotrans.by/local/components/travelsoft/eurotrans.search_form/ajax/dates.php';



const rootElement = document.getElementById("root");


if (rootElement.hasChildNodes()) {
    hydrate(<App/>, rootElement);
} else {
    render(<App/>, rootElement);
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
