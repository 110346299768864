import React, {useEffect, useState} from 'react';
import AutoCompleat from './container/autoCompleat';
import axios from 'axios';
import qs from 'qs';
import arrivalData from './arrivalPoints.json';
import SwitchButton from './components/switchButton';
import DatePicker from './container/DayPickerPopper';
import Counter from './container/counterPessangers';
import dateFnsFormat from 'date-fns/format';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import './response.css';

const URL_ARRIVEL = '/local/components/travelsoft/eurotrans.search_form/ajax/arrivalpoints.php';
const URL_DATES =  '/local/components/travelsoft/eurotrans.search_form/ajax/dates.php';

const root_elem = document.querySelector('#root');
function renderInPage(){
    return root_elem.dataset.place == 'main' ? true : false
}
function setStorage(key,data) {
    sessionStorage.setItem(`eurotrans_form[${key}]`,JSON.stringify(data))
}
function getStorage(key,defData){
    return sessionStorage.getItem(key) ? JSON.parse(sessionStorage.getItem(key)) : defData
}
function revers(key1,key2,data){
    let buf = data[key1];
    data[key1]=data[key2];
    data[key2]= buf;
    return {...data};
}
function updateKeyName(str,obj){
    let buf ={};
    for(let key in obj){
        buf[`${str}[${key}]`] = obj[key];
    }
    return buf;
}
async function getDataUrl(url,body,callback){
    try{
       let result = await axios.post(url,qs.stringify(body));
       return callback(result.data);
    }
    catch(error){
        console.log(error);
    }
}
async function getDataUrlMiddleware(url,body,middleWare,callback){
    try{
       let result = await axios.post(url,qs.stringify(body));
       return callback(middleWare(result.data));
    }
    catch(error){
        console.log(error);
    }
}
function disable(data1,data2) {
   return  (data1.length > 0 && data2.length > 0) && true
}
function regReplace(data,callback) {
    return data.map(item=>{
        let re = /([^\)]+)\((.*)\)/;
        let point = item.name.match(re);
        if(point !== null){
            item.point = point[2];
            item.shortName = point[1];
        }
        else {
            item.point = null;
            item.shortName = null;
        }
        return item;
    });
}
function getCoords(elem) {
    let box = elem.getBoundingClientRect();

    return {
        top: box.top + window.pageYOffset,
        left: box.left + window.pageXOffset
    };
}

function App() {
    //createStorage();
    const [dataFrom,setDataFrom] = useState([]);
    const [dataTo,setDataTo] = useState([]);
    const [dates, setDates] = useState([]);
    const [switched, setSwitchData] = useState(false);
    const theme = useTheme();
    const xs = useMediaQuery(theme.breakpoints.down('xs'));
    const md = useMediaQuery(theme.breakpoints.down('md'));
    const sm = useMediaQuery(theme.breakpoints.down('sm'));

    const [body,setBody] = useState({
        sessid:'',
        location_from:getStorage('eurotrans_form[location_from]',"2"),
        location_to:getStorage('eurotrans_form[location_to]',''),
        date_from:getStorage('eurotrans_form[date_from]',dateFnsFormat(new Date(),'dd.MM.yyyy')),
        date_back:getStorage('eurotrans_form[date_back]',''),
        pessangers:getStorage('eurotrans_form[pessangers]',{
            adults:1,
            children:0,
            social:0
        }),
    });
    useEffect(()=>{
        let buf = JSON.parse(document.getElementById('root').dataset.from);
        setDataFrom(regReplace(buf));
        window.onload = () =>{
            if(document.querySelector('.booking-block.js-book-block')){
                let coords = getCoords(document.querySelector('.booking-block.js-book-block'));
                window.scrollTo({
                    top: coords.top + 400,
                    behavior: "smooth"
                });
            }
            if(root_elem.dataset.hasOwnProperty('operator')) {
                if(root_elem.dataset.operator == "1"){
                    document.querySelector('[data-clear-form]').onclick = (e) =>{

                        e.preventDefault();

                        setStorage('data_from','');
                        setStorage('date_back','');
                        setStorage('pessangers',{adults:1, children:0, social:0});
                        window.location.href='/';
                    }
                }
            }

        };
        if(root_elem.dataset.hasOwnProperty('cache')){
            let cache = JSON.parse(root_elem.dataset.cache);
            if(cache){
                for (let key in cache){
                    if(key == 'location_from' || key == 'location_to'){
                        body[key] = cache[key];
                        setStorage([key],cache[key]);
                    }
                }
            };

            setBody({...body});
        }




    },[]);



    useEffect(()=>{
        getDataUrlMiddleware(URL_ARRIVEL,body,regReplace,setDataTo);
    },[body.location_from]);


    useEffect(()=>{
        getDataUrl(URL_DATES,body,setDates);
    },[body.location_to]);

    const set_data = key => data =>{
        setBody(()=>{
            body[key] = data;
            return {...body}
        });
        setStorage(key,data);
        switch (key) {
            case 'location_from':
                setBody(()=>{
                    body.date_from = dateFnsFormat(new Date(),'dd.MM.yyyy');
                    body.date_back = [];
                    body.location_to='';
                    return {...body};
                });
                setStorage('date_from',dateFnsFormat(new Date(),'dd.MM.yyyy'));
                setStorage('date_back','');
                break;
            case 'location_to':{
                setBody(()=>{
                    body.date_from = dateFnsFormat(new Date(),'dd.MM.yyyy');
                    body.date_back = '';
                    return {...body};
                });
                setStorage('date_from',dateFnsFormat(new Date(),'dd.MM.yyyy'));
                setStorage('date_back','');
            }
            break;
            case 'date_from':{
                setBody(()=>{
                    body.date_back = '';
                    return {...body};
                });
                setStorage('date_back','');
            }
        }
    };

    const switchData = () =>{
        if(body.location_to.length > 0 && body.location_from.length > 0){
            setSwitchData(true);
           let buf = {...revers('location_from','location_to',body)};
            sessionStorage.clear();
            for (let key in buf){
                setStorage(key,buf[key]);
            }
            setBody(buf);
        }
    };
    const handleSendData = () =>{
        let {pessangers, ...stringBody} = body;
        stringBody = updateKeyName('eurotrans',stringBody);
        pessangers = updateKeyName('eurotrans',pessangers);
        window.location.href = `/event-registration/?${body.date_back.length > 0 ? "eurotrans%5Bround_trip%5D=1&" : ''}${qs.stringify({...stringBody,...pessangers})}`;
    };


    console.log();
   return (
      <div className="search_form__container">
        <div id="search_form" className="search_form__box"  >
            <div className={`${renderInPage() ? 'search_main_form__from-back_container' : 'search_inner-from_from-to_container'}`}>
          <AutoCompleat
              textLabel={'Откуда'}
              textPopper={'Выберите пункт отправления'}
              initData={dataFrom}
              defData={body.location_from}
              id={'from'}
              name={'eurotrans[location_from]'}
              callback={set_data('location_from')}/>
            <SwitchButton callback={switchData}  disable={disable(body.location_from, body.location_to)}/>
          <AutoCompleat
              textLabel={'Куда'}
              id={'to'}
              textPopper={'Выберите пункт прибытия'}
              initData={dataTo}
              defData={body.location_to}
              name={'eurotrans[location_to]'}
              callback={set_data('location_to')}
          />
            </div>
            <DatePicker
                initData={dates[body.location_from]}
                textLabel={'Туда'}
                disabled={body.location_to.length > 0 ? false : true}
                id={'date_from'}
                textPopper={'Выберите дату отправления'}
                placeholder={'Выберите дату отправления'}
                defData={body.date_from}
                name={'eurotrans[date_from]'}
                callback={set_data('date_from')}
                clearInput = {false}
            />
            <DatePicker
                initData={dates[body.location_to]}
                disabled={body.location_to.length > 0 ? false : true}
                textLabel={'Обратно'}
                id={'date_back'}
                minDate = {body.date_from}
                textPopper={'Выберите дату возвращения'}
                placeholder={''}
                defData={body.date_back}
                name={'eurotrans[date_back]'}
                callback={set_data('date_back')}
                clearInput = {true}
            />
            <Counter
                textLabel={'Пассажиры'}
                textPopper={'Укажите количество пассажиров'}
                callback={set_data('pessangers')}
                defData = {body.pessangers}
                initData = {body.pessangers}
                name={['eurotrans[adults]','eurotrans[children]','eurotrans[social]']}
            />

            <div className={`${renderInPage() ? 'search_form__submit_box' : 'search_inner-form__submit_box'}`}>
                <button type={'submit'} className={`search_form__submit ${body.location_to.length == 0 ? 'disable_submit' : ''}`} onClick={handleSendData} disabled={body.location_to.length == 0 && true}> Найти билеты </button>
            </div>

        </div>

      </div>

);
}

export default App;
//https://eurotrans.by/event-registration/?eurotrans%5Blocation_from%5D=2&eurotrans%5Blocation_to%5D=9&eurotrans%5Bdate_from%5D=09.09.2019&eurotrans%5Bdate_back%5D=&eurotrans%5Badults%5D=1&eurotrans%5Bchildren%5D=1&eurotrans%5Bsocial%5D=1