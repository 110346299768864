import React from 'react';
import './style.css';
const SwitchButton = ({callback=()=>{}, disable}) => {

    const handleClick = () =>{
        disable && callback();
    };

    return (
        <div className="search_form__switch">
            <div className={`search_form__switch_button ${!disable && 'search_form__switch_button_disable'}`} onClick={handleClick}>
                <svg xmlns="http://www.w3.org/2000/svg" width="19.165" height="13.416" viewBox="0 0 19.165 13.416">
                    <path id="Path_8" data-name="Path 8"
                          d="M8.717,13.624H2v1.917H8.717v2.875l3.823-3.833L8.717,10.75Zm5.73-.958V9.791h6.717V7.875H14.448V5L10.624,8.833Z"
                          transform="translate(-2 -5)" fill="#393939"/>
                </svg>
            </div>
        </div>
    );
};

export default SwitchButton;