import React, {useEffect, useState, useRef, } from 'react';
import './style.css';
import {Manager, Reference, Popper} from 'react-popper';
import ClickAway from '@material-ui/core/ClickAwayListener';
import CustomDatePicker from "../../components/datePicker";
import CalendarSvg from './calendar';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import dateFnsFormat from "date-fns/format";
import addDays from 'date-fns/addDays';
import CloseIcon from '@material-ui/icons/Close';
import {placeholder} from "@babel/types";
function renderInPage(){
    return document.querySelector('#root').dataset.place == 'main' ? true : false
}
const DayPicker = (props) => {
    //props
    const {
        initData = [],
        callback = () => {
        },
        minDate='',
        defData = '',
        textLabel='',
        textPopper='',
        clearInput,
        id='',
        name='',
        placeholder='',
        disabled = true
    } = props;

    //state
    const [data, setData] = useState(initData);
    const [open, setOpen] = useState(false);
    const [input, setInput] = useState(defData);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));
    //ref
    //effect

    useEffect(()=>setData(initData),[initData]);
    //function

    const handleClick = (e) => {

        if(!e.target.closest('.close') && disabled == false){
            setOpen(!open);
        }
    };
    const handleToday = (e) =>{
        callback( dateFnsFormat(addDays(new Date(),1),'dd.MM.yyyy'));
        if(!e.target.closest('.search_form__datePicker_control_desktop') ){
            setOpen(!open);
        }

    };
    const handleTomorow = (e) =>{
        callback( dateFnsFormat(addDays(new Date(),2),'dd.MM.yyyy'));

        if(!e.target.closest('.search_form__datePicker_control_desktop')){
            setOpen(!open);
        }

    };
    const handleClear = (e) =>{
        e.preventDefault();
        callback('');
        setOpen(false);
    };

    if(!fullScreen){
        return (

            <Manager>
                <Reference>
                    {({ref}) => (
                        <div ref={ref}
                             className={`search_form__input ${data.length == 0 && 'disable'} ${renderInPage() ? 'date' : 'date_inner' }`}>
                            <label  onClick={handleClick} htmlFor={`search_from__${id}`} className={`search_form__input_label ${(placeholder.length == 0 && defData.length == 0) && 'height_100'}`}>
                                {textLabel}
                            </label>
                            <div  onClick={handleClick}
                                  autoComplete="off"
                                   id={`search_from__${id}`}
                                   type="text"
                                   className={`search_form__input_inputText ${(placeholder.length == 0 && defData.length == 0) && 'height_0'}`}
                                   placeholder
                                   name={id}
                                   readOnly
                                   disabled={data.length > 0 && false}
                                   onChange={()=>{}}

                                    // style={{height:`${placeholder.length  == 0  && 0}`}}
                            >
                                {defData}
                            </div>
                            {
                                (defData.length != 0 && clearInput != false) ?
                                    <div className={'search_form_icon close'}>
                                        <CloseIcon onClick={handleClear}/>
                                    </div>
                                        : clearInput == false &&
                                    <div className={'search_form_icon'} onClick ={handleClick} style={{cursor:'pointer'}}>
                                        <CalendarSvg />
                                    </div>

                            }
                            {
                                (clearInput == false &&  !fullScreen) &&
                                <div className={'search_form__datePicker_control_desktop'}>
                                    <button onClick={handleToday}>Завтра</button>
                                    <button onClick={handleTomorow}>Послезавтра</button>
                                </div>

                            }
                        </div>




                    )}
                </Reference>

                {
                    open &&
                    <Popper placement="bottom-start">
                        {({ref, style, placement}) => (
                            <div ref={ref} style={style} data-placement={placement}>

                                <ClickAway onClickAway={()=>setOpen(false)} >
                                    <div className={'search_form__datePicker_container'}>

                                        <div className="search_form__datePicker_header">{textPopper}</div>
                                        <div className="search_form__datePicker_content">
                                            {
                                                clearInput &&
                                                <div className="search_form_datePicker_control">
                                                    <button onClick={handleClear} className={'search_form_datePicker_control_not-ticket'}>Обратный билет не нужен</button>
                                                </div>
                                            }

                                            <CustomDatePicker
                                                minDate = {minDate}
                                                initData={initData} callback={callback} setOpen={setOpen} defData={defData}/>
                                        </div>
                                    </div>
                                </ClickAway>
                            </div>
                        )}
                    </Popper>
                }
            </Manager>

        );
    }
    else{
        return <>
            <div
                 onClick={handleClick}
                 className={`search_form__input date ${data.length == 0 && 'disable'}`}>
                <label  onClick={handleClick} htmlFor={`search_from__${id}`} className={`search_form__input_label ${(placeholder.length == 0 && defData.length == 0) && 'height_100'}`}>
                    {textLabel}
                </label>

                <div  onClick={handleClick} autoComplete="off"
                      id={`search_from__${id}`}
                      type="text"
                      className={`search_form__input_inputText ${(placeholder.length == 0 && defData.length == 0) && 'height_0'}`}
                      placeholder
                      name={id}
                      disabled={data.length > 0 && false}
                      onChange={()=>{}}

                    // style={{height:`${placeholder.length  == 0  && 0}`}}
                >
                    {defData}
                </div>
                {
                    (defData.length != 0 && clearInput != false) ?
                        <div className={'search_form_icon close'}>
                            <CloseIcon onClick={handleClear}/>
                        </div>
                        : clearInput == false &&
                        <div className={'search_form_icon'} onClick ={handleClick} style={{cursor:'pointer'}}>
                            <CalendarSvg/>
                        </div>

                }


            </div>
        <Dialog open={open} fullScreen={fullScreen}>
            <div className={'search_form__dialog_content'}>
                <div className="search_form__inputModal_inputText">{textPopper}
                    <CloseIcon onClick ={()=>setOpen(!open)}/>
                </div>
                <div className="search_form_datePicker_control">
                    {
                        clearInput &&  <button onClick={handleClear} style={{maxWidth:'100%',marginBottom:'0.5rem'}}>Обратный билет не нужен</button>
                    }
                    <button onClick={handleToday}>Завтра</button>
                    <button onClick={handleTomorow}>Послезавтра</button>
                </div>
                <div className={'search_form__datePicker_container'}>
                <div className="search_form__datePicker_content">
                    <CustomDatePicker
                        initData={initData}
                        callback={callback}
                        minDate = {minDate}
                        setOpen={setOpen}
                        defData={defData}/>
                </div>

                </div>

            </div>
        </Dialog>
            </>
    }

};

export default DayPicker;