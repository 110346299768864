import React, {useEffect, useState, useRef} from 'react';
import './style.css';
import {Manager, Reference, Popper} from 'react-popper';
import Counter from '../../components/counter';
import PeopleIcon from './peopleIcon';
import ClickAway from '@material-ui/core/ClickAwayListener';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import CloseIcon from '@material-ui/icons/Close';
function renderInPage(){
    return document.querySelector('#root').dataset.place == 'main' ? true : false
}
function sumArr(data){
    return data.reduce((prev,current)=> prev+current)
}

const CounterPessagners = (props) => {
    //props
    const {
        initData = [],
        callback = () => {
        },
        defData = '',
        textLabel = '',
        textPopper = '',
        id = '',
        name=''
    } = props;
    //state
    const [data, setData] = useState(initData);
    const [open, setOpen] = useState(false);
    const [input, setInput] = useState(defData);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));
    //ref
    //effect
    useEffect(()=>setData(initData),[initData]);

    //function

    const handleClick = (e) => {
        if (e.target.closest('.search_form__input')) {
            setOpen(!open);
        }
    };
    const handleCountPessangers = key => value => {
        initData[key]=value;
        callback(initData);
    };

    if(!fullScreen) {

        return (

            <Manager>
                <Reference>
                    {({ref}) => (
                        <div ref={ref}
                             onClick={handleClick}
                             className={`search_form__input ${data.length == 0 && 'disable'} ${renderInPage() ? 'pessangers' : 'pessangers_inner'}`}>
                            <label htmlFor={`search_from__${id}`} className="search_form__input_label"
                            >
                                {textLabel}
                            </label>
                            <input autoComplete="off"
                                   id={`search_from__${id}`}
                                   type="text"
                                   name={id}
                                   readOnly
                                   className="search_form__input_inputText"
                                   placeholder={textPopper}
                                   value={sumArr(Object.values(initData))}
                                   disabled={data.length > 0 && false}
                                   onChange={() => {
                                   }}

                            />
                            <PeopleIcon/>
                        </div>
                    )}
                </Reference>

                {
                    open &&
                    <Popper placement="bottom-end">
                        {({ref, style, placement}) => (
                            <div ref={ref} style={style} data-placement={placement}>
                                <ClickAway onClickAway={() => setOpen(false)}>
                                    <div className={'search_form__counter_container'}>
                                        <div className="search_form__counter_header">{textPopper}</div>
                                        <div className="search_form__counter_content">
                                            <Counter callback={handleCountPessangers('adults')}
                                                     initData={initData.adults}
                                                     name={name[0]}
                                            >
                                                <span>Взрослых</span>
                                                <small>от 12 и старше</small>
                                            </Counter>
                                            <Counter callback={handleCountPessangers('children')}
                                                     name={name[1]}
                                                     initData={initData.children}>
                                                <span>Детей</span>
                                                <small>До 12 лет</small>
                                            </Counter>
                                            <Counter callback={handleCountPessangers('social')}
                                                     name={name[2]}
                                                     initData={initData.social}>
                                                <span>Льготных</span>
                                                <small>
                                                    Студенты до 25;<br/>
                                                    Старше 60;<br/>
                                                    Инвалиды 1,2,3 гр.
                                                </small>
                                            </Counter>
                                        </div>
                                    </div>
                                </ClickAway>
                            </div>
                        )}
                    </Popper>
                }
            </Manager>

        );
    }
    else return <>
        <div
             onClick={handleClick}
             className={`search_form__input pessangers ${data.length == 0 && 'disable'}`}>
            <label htmlFor={`search_from__${id}`} className="search_form__input_label"
            >
                {textLabel}
            </label>
            <input autoComplete="off"
                   id={`search_from__${id}`}
                   type="text"
                   name={id}
                   readOnly
                   className="search_form__input_inputText"
                   placeholder={textPopper}
                   value={sumArr(Object.values(initData))}
                   disabled={data.length > 0 && false}
                   onChange={() => {
                   }}
            />
            <PeopleIcon/>
        </div>
        <Dialog open={open} fullScreen={fullScreen}>
            <div className={'search_form__dialog_content'}>
                <div className="search_form__inputModal_inputText">{textPopper}
                <CloseIcon onClick ={()=>setOpen(!open)}/>
                </div>
                <div className={'search_form__counter_container'}>
                    <div className="search_form__counter_header">{textPopper}</div>
                    <div className="search_form__counter_content">
                        <Counter callback={handleCountPessangers('adults')}
                                 initData={initData.adults}
                                 name={name[2]}
                        >
                            <span>Взрослых</span>
                            <small>от 12 и старше</small>
                        </Counter>
                        <Counter callback={handleCountPessangers('children')}
                                 initData={initData.children}
                                 name={name[2]}
                        >
                            <span>Детей</span>
                            <small>До 12 лет</small>
                        </Counter>
                        <Counter callback={handleCountPessangers('social')}
                                 initData={initData.social}
                                 name={name[2]}
                        >
                            <span>Льготных</span>
                            <small>
                                Студенты до 25;<br/>
                                Старше 60;<br/>
                                Инвалиды 1,2,3 гр.
                            </small>
                        </Counter>

                    </div>

                </div>
                <div className="search_form__counter_action">
                    <button onClick={()=>setOpen(!open)}>Готово</button>
                </div>
            </div>
        </Dialog>
    </>
};

export default CounterPessagners;